@import "../variables.scss";

.footer {
  z-index: 10;
  position: fixed;
  text-transform: uppercase;
  font-size: 14px;
  color: $color-grey;
  letter-spacing: 5px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  bottom: 50%;
  left: -75px;
}

@media screen and (max-width: 840px) {
  .footer {
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .footer {
    display: none;
  }
}
