@import "../variables.scss";

.btn {
  position: relative;
  display: inline-block;
  font-family: $font-header;
  color: $color-white;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  z-index: 1;
  padding: 16px 30px;
  height: 100%;
  background-color: $color-black;
  border: 1px solid $color-grey;
  &:hover {
    background-color: $color-white;
    color: $color-black;
    border-left: 2px solid $color-one;
    border-bottom: 2px solid $color-two;
  }
}

@media screen and (max-width: 840px) {
  .btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}
