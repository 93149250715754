@import "../variables.scss";

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 20px;
    letter-spacing: 10px;
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
  }
  p {
    font-size: 18px;
    text-align: center;
  }
  a {
    color: $color-white;
    font-size: 32px;
    :hover {
      color: $color-two;
    }
  }
  ul {
    list-style: none;
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-top: 20px;
  }
  .divider {
    display: block;
    height: 5px;
    width: 50px;
    background-color: $color-one !important;
    margin: 30px 0;
  }
}

@media screen and (max-width: 600px) {
  .contact {
    h1 {
      margin-bottom: 20px;
      letter-spacing: 10px;
      text-transform: uppercase;
      font-size: 32px;
    }
    p {
      font-size: 18px;
      text-align: center;
    }
    a {
      color: $color-white;
      font-size: 20px;
      :hover {
        color: $color-two;
      }
    }
    ul {
      list-style: none;
      display: flex;
      width: 250px;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}
