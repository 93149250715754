@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: $font-text;
  background-color: $color-black;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
p {
  color: $color-grey;
  line-height: 1.7em;
}
h1 {
  color: $color-white;
  font-family: $font-header;
}
h2 {
  color: $color-white;
  font-family: $font-header;
}
h3 {
  color: $color-white;
  font-family: $font-header;
}
h4 {
  color: $color-white;
  font-family: $font-header;
}
h5 {
  color: $color-white;
  font-family: $font-header;
}
h6 {
  color: $color-white;
  font-family: $font-header;
}
ul {
  padding-left: 0;
  margin: 0;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
img {
  max-width: 100%;
}
button {
  &:focus {
    outline: none;
  }
}

.content-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  .content {
    width: 80vw;
    align-self: center;
    &:hover {
      h4 {
        &:before {
          right: -90px;
        }
      }
    }
  }
}
