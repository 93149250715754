@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&family=Source+Sans+Pro:wght@300;400;700;900&display=swap");

$width-small: 600px;
$width-medium: 1000px;
$width-large: 1600px;

$font-header: "Oswald", sans-serif;
$font-text: "Source Sans Pro", sans-serif;

$color-one: #ff458f;
$color-two: #53cfda;
$color-black: #000;
$color-grey: #999;
$color-white: #fff;
$color-offwhite: #f3dddd;
